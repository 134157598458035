$(document).foundation();

$( "#user-menu" ).click(function() {
    $( this ).toggleClass( "open" );
});

$( "#user-menu > .toggle-menu" ).click(function() {
    $( this ).toggleClass( "open" );
});

$( ".filter-selector-input" ).click(function() {
    $( this ).parent( ".filter-selector-wrapper").parent( ".filter-selection").parent( ".filter").toggleClass( "open" );
});

$( ".safesearch-dropdown" ).click(function() {
    $( this ).parent( ".safesearch-input").parent( ".safesearch-wrapper").toggleClass( "open" );
});

$( ".sorting-input" ).click(function() {
    $( this ).parent( ".sorting-wrapper").toggleClass( "open" );
});

$( ".toggle-filter" ).click(function() {
    $( this ).toggleClass( "open" );
    $( "#filter-left" ).toggleClass( "open" );
    $( ".main-wrapper" ).toggleClass( "no-scroll" );
    $( "#footer-main" ).toggleClass( "no-scroll" );
});

$( ".multiselection > label" ).click(function() {
    $( this ).parent( ".filter-selector-option-options-wrapper").toggleClass( "open" );
});